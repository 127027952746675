import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { hasQueryParam } from '../../util/urlHelpers';
import { filterCompletedListings } from '../../util/listingsHelpers';
import { fetchCreators, fetchStores } from '../../ducks/user.duck';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/StorePage/SET_INITIAL_STATE';

export const SHOW_USER_REQUEST = 'app/StorePage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/StorePage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/StorePage/SHOW_USER_ERROR';

export const QUERY_LISTINGS_REQUEST = 'app/StorePage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/StorePage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/StorePage/QUERY_LISTINGS_ERROR';

export const QUERY_REVIEWS_REQUEST = 'app/StorePage/QUERY_REVIEWS_REQUEST';
export const QUERY_REVIEWS_SUCCESS = 'app/StorePage/QUERY_REVIEWS_SUCCESS';
export const QUERY_REVIEWS_ERROR = 'app/StorePage/QUERY_REVIEWS_ERROR';

export const SET_CATEGORIES = 'app/StorePage/SET_CATEGORIES';
export const SET_SUBCATEGORIES = 'app/StorePage/SET_SUBCATEGORIES';
export const SET_CURRENT_CATEGORY = 'app/StorePage/SET_CURRENT_CATEGORY';
export const SET_CURRENT_SUBCATEGORY = 'app/StorePage/SET_CURRENT_SUBCATEGORY';

// ================ Reducer ================ //

const initialState = {
  userId: null,
  userListingRefs: [],
  userShowError: null,
  fetchingListings: true,
  queryListingsError: null,
  reviews: [],
  queryReviewsError: null,
  categories: [],
  subcategories: [],
  currentCategory: 'all',
  currentSubcategory: 'all'
};

export default function storePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case SHOW_USER_REQUEST:
      return { ...state, userShowError: null, userId: payload.userId };
    case SHOW_USER_SUCCESS:
      return state;
    case SHOW_USER_ERROR:
      return { ...state, userShowError: payload };

    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,
        fetchingListings: true,

        // Empty listings only when user id changes
        userListingRefs: payload.userId === state.userId ? state.userListingRefs : [],

        queryListingsError: null,
      };
    case QUERY_LISTINGS_SUCCESS:
      return { ...state, fetchingListings: false, userListingRefs: payload.listingRefs };
    case QUERY_LISTINGS_ERROR:
      return { ...state, fetchingListings: false, userListingRefs: [], queryListingsError: payload };
    case QUERY_REVIEWS_REQUEST:
      return { ...state, queryReviewsError: null };
    case QUERY_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case QUERY_REVIEWS_ERROR:
      return { ...state, reviews: [], queryReviewsError: payload };

    case SET_CATEGORIES:
      return { ...state, categories: payload };
    case SET_SUBCATEGORIES:
      return { ...state, subcategories: payload };
    case SET_CURRENT_CATEGORY:
      return { ...state, currentCategory: payload };
    case SET_CURRENT_SUBCATEGORY:
      return { ...state, currentSubcategory: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const showUserRequest = userId => ({
  type: SHOW_USER_REQUEST,
  payload: { userId },
});

export const showUserSuccess = () => ({
  type: SHOW_USER_SUCCESS,
});

export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = userId => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { userId },
});

export const queryListingsSuccess = listingRefs => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listingRefs },
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryReviewsRequest = () => ({
  type: QUERY_REVIEWS_REQUEST,
});

export const queryReviewsSuccess = reviews => ({
  type: QUERY_REVIEWS_SUCCESS,
  payload: reviews,
});

export const queryReviewsError = e => ({
  type: QUERY_REVIEWS_ERROR,
  error: true,
  payload: e,
});

export const setCategories = e => ({
  type: SET_CATEGORIES,
  payload: e,
});

export const setSubcategories = e => ({
  type: SET_SUBCATEGORIES,
  payload: e,
});

export const setCurrentCategory = e => ({
  type: SET_CURRENT_CATEGORY,
  payload: e,
});

export const setCurrentSubcategory = e => ({
  type: SET_CURRENT_SUBCATEGORY,
  payload: e,
});

// ================ Thunks ================ //

export const queryUserListings = (userId, config, search = '', keywords = null) => (dispatch, getState, sdk) => {
  let state = getState();
  let { domLoaded = false } = state?.ui || {};

  dispatch(queryListingsRequest(userId));

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  // const variantPrefix = 'square-small';
  let keywordsMaybe = keywords ? { keywords } : {};

  let queryParams = {
    author_id: userId,
    ...keywordsMaybe,
    include: ['author', 'author.profileImage', 'images'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.profileImage', 'profile.publicData'],
    'fields.image': [
      'variants.scaled-small',
      'variants.scaled-medium',
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      'variants.square-small',
      'variants.square-small2x',
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  };

  const filter = hasQueryParam({search}, 'pub_category', true);
  const filterSubcategory = hasQueryParam({search}, 'pub_subcategory', true);

  const listingFields = config?.listing?.listingFields || [];
  let filterCategories = [];
  const categories = listingFields.find(lf => lf.key == 'category');
  if (categories && categories.enumOptions && categories.enumOptions.length) {
    filterCategories = categories.enumOptions;
  }
  dispatch(setCategories(filterCategories));

  let currentCategory = 'all';
  if (filter && filter !== 'all') {
    queryParams['pub_category'] = filter;
    currentCategory = filter;
  }
  dispatch(setCurrentCategory(currentCategory));

  let filterSubcategories = [];
  const subcategories = listingFields.find(lf => lf.key == 'subcategory');
  if (subcategories && subcategories.enumOptions && subcategories.enumOptions.length) {
    if (currentCategory !== 'all') {
      filterSubcategories = subcategories.enumOptions.filter(fs => fs.option.includes(`-only${currentCategory}`));
    }
  }
  dispatch(setSubcategories(filterSubcategories));

  let currentSubcategory = 'all';
  if (filterSubcategory) {
    let inOptions = filterSubcategories.find(fs => fs.option === filterSubcategory);
    if (inOptions) {
      if (filterSubcategory !== 'all') {
        queryParams['pub_subcategory'] = filterSubcategory;
      }
      currentSubcategory = filterSubcategory;
    }
  }
  dispatch(setCurrentSubcategory(currentSubcategory));

  return sdk.listings
    .query(queryParams)
    .then(response => {
      const creatorsId = (response?.data?.data || []).map(l => l?.attributes?.publicData?.creatorId).filter(l => l);
      if (domLoaded) {
        dispatch(fetchCreators(creatorsId));
        dispatch(fetchStores());
      }
      // Pick only the id and type properties from the response listings
      response.data.data = filterCompletedListings(response?.data?.data || []);
      const listingRefs = response.data.data.map(({ id, type }) => ({ id, type }));
      dispatch(addMarketplaceEntities(response));
      dispatch(queryListingsSuccess(listingRefs));
      return response;
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const showUser = userId => (dispatch, getState, sdk) => {
  dispatch(showUserRequest(userId));
  return sdk.users
    .show({
      id: userId,
      // include: ['profileImage'],
      // 'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(showUserSuccess());
      return response;
    })
    .catch(e => dispatch(showUserError(storableError(e))));
};

export const reloadListings = (params, keywords, config) => dispatch => {
  const userId = new UUID(params.id);
  dispatch(queryUserListings(userId, config, '', keywords));
};

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  const userId = new UUID(params.id);

  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  return Promise.all([
    dispatch(showUser(userId)),
    dispatch(queryUserListings(userId, config, search)),
  ]);
};
