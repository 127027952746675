import React, { useState } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  array,
  arrayOf,
  bool,
  func,
  node,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import loadable from '@loadable/component';
import classNames from 'classnames';
import omit from 'lodash/omit';

import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import {
  displayDeliveryPickup,
  displayDeliveryShipping,
  displayPrice,
} from '../../util/configHelpers';
import {
  propTypes,
  LISTING_STATE_CLOSED,
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  LINE_ITEM_ITEM,
  LINE_ITEM_HOUR,
  STOCK_MULTIPLE_ITEMS,
  STOCK_INFINITE_MULTIPLE_ITEMS,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import { userDisplayNameAsString } from '../../util/data';
import {
  INQUIRY_PROCESS_NAME,
  getSupportedProcessesInfo,
  isBookingProcess,
  isPurchaseProcess,
  resolveLatestProcessName,
} from '../../transactions/transaction';

import { ModalInMobile, PrimaryButton, AvatarSmall, H1, H2, Modal, SecondaryButton, IconIntercom } from '../../components';

import css from './OrderPanel.module.css';

const BookingTimeForm = loadable(() =>
  import(/* webpackChunkName: "BookingTimeForm" */ './BookingTimeForm/BookingTimeForm')
);
const BookingDatesForm = loadable(() =>
  import(/* webpackChunkName: "BookingDatesForm" */ './BookingDatesForm/BookingDatesForm')
);
const InquiryWithoutPaymentForm = loadable(() =>
  import(
    /* webpackChunkName: "InquiryWithoutPaymentForm" */ './InquiryWithoutPaymentForm/InquiryWithoutPaymentForm'
  )
);
const ProductOrderForm = loadable(() =>
  import(/* webpackChunkName: "ProductOrderForm" */ './ProductOrderForm/ProductOrderForm')
);

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openOrderModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search, {}, true), orderOpen: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeOrderModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search, {}, true), 'orderOpen');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const handleSubmit = (
  isOwnListing,
  isClosed,
  isInquiryWithoutPayment,
  onSubmit,
  history,
  location
) => {
  // TODO: currently, inquiry-process does not have any form to ask more order data.
  // We can submit without opening any inquiry/order modal.
  return isInquiryWithoutPayment
    ? () => onSubmit({})
    : () => openOrderModal(isOwnListing, isClosed, history, location);
};

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const PriceMaybe = props => {
  const {
    price,
    publicData,
    validListingTypes,
    intl,
    marketplaceCurrency,
    showCurrencyMismatch = false,
  } = props;
  const { listingType, unitType } = publicData || {};

  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice || !price) {
    return null;
  }

  // Get formatted price or currency code if the currency does not match with marketplace currency
  const { formattedPrice, priceTitle } = priceData(price, marketplaceCurrency, intl);
  // TODO: In CTA, we don't have space to show proper error message for a mismatch of marketplace currency
  //       Instead, we show the currency code in place of the price
  return showCurrencyMismatch ? (
    <div className={css.priceContainerInCTA}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      <div className={css.perUnitInCTA}>
        <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
      </div>
    </div>
  ) : (
    <div className={css.priceContainer}>
      <p className={css.price}>{formatMoney(intl, price)}</p>
      <div className={css.perUnit}>
        <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
      </div>
    </div>
  );
};

const OrderPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    validListingTypes,
    lineItemUnitType: lineItemUnitTypeMaybe,
    isOwnListing,
    onSubmit,
    title,
    titleDesktop,
    author,
    authorLink,
    onManageDisableScrolling,
    onFetchTimeSlots,
    monthlyTimeSlots,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    onContactUser,
    lineItems,
    marketplaceCurrency,
    dayCountAvailableForBooking,
    marketplaceName,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    payoutDetailsWarning,
    metadata,
    listingConfig,
    description,
    isIframe,
    pageUrl,
    showOfferModal,
    setShowOfferModal,
    fromListingPage,
    offerPrice,
    counterOfferPrice,
    isAuthenticated,
    currentUser,
  } = props;

  let { userType = 'buyer' } = currentUser?.attributes?.profile?.publicData || {};
  let isGuestOrBuyer = userType == 'buyer';
  const [copied, setCopied] = useState(false);

  const { listingFields } = listingConfig || {};
  const publicData = listing?.attributes?.publicData || {};

  const pickListingFields = (filteredConfigs, config) => {
    const { key, schemaType, enumOptions, includeForListingTypes, showConfig = {} } = config;
    const listingType = publicData.listingType;
    const isTargetListingType =
      includeForListingTypes == null || includeForListingTypes.includes(listingType);

    const { isDetail, label } = showConfig;
    const publicDataValue = publicData[key];
    const metadataValue = metadata[key];
    const value = publicDataValue || metadataValue;

    if (isDetail && isTargetListingType && typeof value !== 'undefined') {
      const findSelectedOption = enumValue => enumOptions?.find(o => enumValue === `${o.option}`);
      const getBooleanMessage = value =>
        value
          ? intl.formatMessage({ id: 'SearchPage.detailYes' })
          : intl.formatMessage({ id: 'SearchPage.detailNo' });
      const optionConfig = findSelectedOption(value);

      return schemaType === 'enum'
        ? filteredConfigs.concat({ key, value: optionConfig?.label, label })
        : schemaType === 'boolean'
          ? filteredConfigs.concat({ key, value: getBooleanMessage(value), label })
          : schemaType === 'long'
            ? filteredConfigs.concat({ key, value, label })
            : filteredConfigs;
    }
    return filteredConfigs;
  };

  const existingListingFields = listingFields && listingFields.length ? listingFields.reduce(pickListingFields, []) : [];

  const { listingType, unitType, transactionProcessAlias = '' } = publicData || {};
  const processName = resolveLatestProcessName(transactionProcessAlias.split('/')[0]);
  const lineItemUnitType = lineItemUnitTypeMaybe || `line-item/${unitType}`;

  const price = listing?.attributes?.price;
  const isPaymentProcess = processName !== INQUIRY_PROCESS_NAME;

  const showPriceMissing = isPaymentProcess && !price;
  const PriceMissing = () => {
    return (
      <p className={css.error}>
        <FormattedMessage id="OrderPanel.listingPriceMissing" />
      </p>
    );
  };
  const showInvalidCurrency = isPaymentProcess && price?.currency !== marketplaceCurrency;
  const InvalidCurrency = () => {
    return (
      <p className={css.error}>
        <FormattedMessage id="OrderPanel.listingCurrencyInvalid" />
      </p>
    );
  };

  const timeZone = listing?.attributes?.availabilityPlan?.timezone;
  const isClosed = listing?.attributes?.state === LISTING_STATE_CLOSED;

  const isBooking = isBookingProcess(processName);
  const shouldHaveBookingTime = isBooking && [LINE_ITEM_HOUR].includes(lineItemUnitType);
  const showBookingTimeForm = shouldHaveBookingTime && !isClosed && timeZone;

  const shouldHaveBookingDates =
    isBooking && [LINE_ITEM_DAY, LINE_ITEM_NIGHT].includes(lineItemUnitType);
  const showBookingDatesForm = shouldHaveBookingDates && !isClosed && timeZone;

  // The listing resource has a relationship: `currentStock`,
  // which you should include when making API calls.
  const isPurchase = isPurchaseProcess(processName);
  const currentStock = listing.currentStock?.attributes?.quantity;
  const isOutOfStock = isPurchase && lineItemUnitType === LINE_ITEM_ITEM && currentStock === 0;

  // Show form only when stock is fully loaded. This avoids "Out of stock" UI by
  // default before all data has been downloaded.
  const showProductOrderForm = isPurchase && typeof currentStock === 'number';

  const showInquiryForm = processName === INQUIRY_PROCESS_NAME;

  const supportedProcessesInfo = getSupportedProcessesInfo();
  const isKnownProcess = supportedProcessesInfo.map(info => info.name).includes(processName);

  const { pickupEnabled, shippingEnabled } = listing?.attributes?.publicData || {};

  const listingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const displayShipping = displayDeliveryShipping(listingTypeConfig);
  const displayPickup = displayDeliveryPickup(listingTypeConfig);
  const allowOrdersOfMultipleItems = [STOCK_MULTIPLE_ITEMS, STOCK_INFINITE_MULTIPLE_ITEMS].includes(
    listingTypeConfig?.stockType
  );

  const showClosedListingHelpText = listing.id && isClosed;
  const isOrderOpen = !!parse(location.search).orderOpen;

  const subTitleText = showClosedListingHelpText
    ? intl.formatMessage({ id: 'OrderPanel.subTitleClosedListing' })
    : null;

  const authorDisplayName = userDisplayNameAsString(author, '');

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.orderTitle);

  const modalBody = showPriceMissing ? (
    <PriceMissing />
  ) : showInvalidCurrency ? (
    <InvalidCurrency />
  ) : showBookingTimeForm ? (
    <BookingTimeForm
      className={css.bookingForm}
      formId="OrderPanelBookingTimeForm"
      lineItemUnitType={lineItemUnitType}
      onSubmit={onSubmit}
      price={price}
      marketplaceCurrency={marketplaceCurrency}
      dayCountAvailableForBooking={dayCountAvailableForBooking}
      listingId={listing.id}
      isOwnListing={isOwnListing}
      monthlyTimeSlots={monthlyTimeSlots}
      onFetchTimeSlots={onFetchTimeSlots}
      startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
      endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
      timeZone={timeZone}
      marketplaceName={marketplaceName}
      onFetchTransactionLineItems={onFetchTransactionLineItems}
      lineItems={lineItems}
      fetchLineItemsInProgress={fetchLineItemsInProgress}
      fetchLineItemsError={fetchLineItemsError}
      payoutDetailsWarning={payoutDetailsWarning}
    />
  ) : showBookingDatesForm ? (
    <BookingDatesForm
      className={css.bookingForm}
      formId="OrderPanelBookingDatesForm"
      lineItemUnitType={lineItemUnitType}
      onSubmit={onSubmit}
      price={price}
      marketplaceCurrency={marketplaceCurrency}
      dayCountAvailableForBooking={dayCountAvailableForBooking}
      listingId={listing.id}
      isOwnListing={isOwnListing}
      monthlyTimeSlots={monthlyTimeSlots}
      onFetchTimeSlots={onFetchTimeSlots}
      timeZone={timeZone}
      marketplaceName={marketplaceName}
      onFetchTransactionLineItems={onFetchTransactionLineItems}
      lineItems={lineItems}
      fetchLineItemsInProgress={fetchLineItemsInProgress}
      fetchLineItemsError={fetchLineItemsError}
      payoutDetailsWarning={payoutDetailsWarning}
    />
  ) : showProductOrderForm ? (
    <ProductOrderForm
    history={history}
      isAuthenticated={isAuthenticated}
      formId="OrderPanelProductOrderForm"
      onSubmit={onSubmit}
      price={price}
      marketplaceCurrency={marketplaceCurrency}
      currentStock={currentStock}
      allowOrdersOfMultipleItems={allowOrdersOfMultipleItems}
      pickupEnabled={pickupEnabled && displayPickup}
      shippingEnabled={shippingEnabled && displayShipping}
      displayDeliveryMethod={displayPickup || displayShipping}
      listingId={listing.id}
      isOwnListing={isOwnListing}
      marketplaceName={marketplaceName}
      onFetchTransactionLineItems={onFetchTransactionLineItems}
      onContactUser={onContactUser}
      lineItems={lineItems}
      fetchLineItemsInProgress={fetchLineItemsInProgress}
      fetchLineItemsError={fetchLineItemsError}
      payoutDetailsWarning={payoutDetailsWarning}
      showOfferModal={showOfferModal}
      setShowOfferModal={setShowOfferModal}
      offerPrice={offerPrice}
      counterOfferPrice={counterOfferPrice}
      isIframe={isIframe}
    />
  ) : showInquiryForm ? (
    <InquiryWithoutPaymentForm formId="OrderPanelInquiryForm" onSubmit={onSubmit} />
  ) : !isKnownProcess ? (
    <p className={css.errorSidebar}>
      <FormattedMessage id="OrderPanel.unknownTransactionProcess" />
    </p>
  ) : null;

  const shareLink = () => {
    navigator.clipboard.writeText(pageUrl);

    let seconds = 5;

    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, seconds * 1000);
  };

  return (
    <div className={classes}>
      <div className={css.modalHeading}>
        <H1 className={css.heading}>{title}</H1>
      </div>
      <div className={css.orderHeading}>
        {/* Get the category field from the existingListingFields */}
        {existingListingFields.map(detail => {
          if (detail.key === 'category') {
            return (
              <div key={detail.key} className={css.category}>
                {detail.value}
              </div>
            );
          }
        })}
        {titleDesktop ? titleDesktop : <H2 className={titleClasses}>{title}</H2>}
        {subTitleText ? <div className={css.orderHelp}>{subTitleText}</div> : null}
      </div>

      <PriceMaybe
        price={counterOfferPrice || offerPrice || price}
        publicData={publicData}
        validListingTypes={validListingTypes}
        intl={intl}
        marketplaceCurrency={marketplaceCurrency}
      />
      <span className={css.orderHelp}>
        <FormattedMessage id="OrderPanel.shippingPrice" />
      </span>

      {description}

      {isIframe && pageUrl !== '' ? <div className={css.sectionShare}><SecondaryButton onClick={shareLink}>{copied ? 'Link copied to clipboard!' : 'Share this listing'}</SecondaryButton></div> : null}

      <div className={css.desktopContainer}>
        {isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="OrderPanel.closedListingButtonText" />
          </div>
        ) : (!isIframe ? (
          <>
            <PrimaryButton
              onClick={handleSubmit(
                isOwnListing,
                isClosed,
                showInquiryForm,
                onSubmit,
                history,
                location
              )}
              disabled={isOutOfStock}
            >
              {isBooking ? (
                <FormattedMessage id="OrderPanel.ctaButtonMessageBooking" />
              ) : isOutOfStock ? (
                <FormattedMessage id="OrderPanel.ctaButtonMessageNoStock" />
              ) : isPurchase ? (
                <FormattedMessage id="OrderPanel.ctaButtonMessagePurchase" />
              ) : (
                <FormattedMessage id="OrderPanel.ctaButtonMessageInquiry" />
              )}
            </PrimaryButton>
            {!isOutOfStock ? <SecondaryButton className={css.offerButton} type="button" inProgress={false} disabled={false} onClick={() => {
              if (isAuthenticated) {
                setShowOfferModal(!showOfferModal);
              } else {
                if (isIframe) {
                  history.push('/signup?iframe');
                } else {
                  history.push('/signup');
                }
              }
            }}>
              <FormattedMessage id="OrderPanel.offerButton" />
            </SecondaryButton> : null}
          </>
        ) : null)}
      </div>

      {isIframe ? modalBody : <Modal
        containerClassName={css.modalContainer}
        id="OrderFormInModal"
        //isModalOpenOnMobile={isOrderOpen}
        isOpen={isOrderOpen}
        onClose={() => closeOrderModal(history, location)}
        //showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        {modalBody}
      </Modal>}

      {!isIframe ? <div className={classNames(css.openOrderForm, {[css.openGuestOrderForm]: isGuestOrBuyer})}>
        <PriceMaybe
          price={price}
          publicData={publicData}
          validListingTypes={validListingTypes}
          intl={intl}
          marketplaceCurrency={marketplaceCurrency}
          showCurrencyMismatch
        />

        {isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="OrderPanel.closedListingButtonText" />
          </div>
        ) : (
          <>
            <PrimaryButton
              className={css.purchaseButton}
              onClick={handleSubmit(
                isOwnListing,
                isClosed,
                showInquiryForm,
                onSubmit,
                history,
                location
              )}
              disabled={isOutOfStock}
            >
              {isBooking ? (
                <FormattedMessage id="OrderPanel.ctaButtonMessageBooking" />
              ) : isOutOfStock ? (
                <FormattedMessage id="OrderPanel.ctaButtonMessageNoStock" />
              ) : isPurchase ? (
                <FormattedMessage id="OrderPanel.ctaButtonMessagePurchase" />
              ) : (
                <FormattedMessage id="OrderPanel.ctaButtonMessageInquiry" />
              )}
            </PrimaryButton>
            <SecondaryButton className={css.offerButton} type="button" inProgress={false} disabled={false} onClick={() => {
              if (isAuthenticated) {
                setShowOfferModal(!showOfferModal);
              } else {
                if (isIframe) {
                  history.push('/signup?iframe');
                } else {
                  history.push('/signup');
                }
              }
            }}>
              <FormattedMessage id="OrderPanel.offerButton" />
            </SecondaryButton>
            {isGuestOrBuyer ? <PrimaryButton
              className={css.intercomButton}
              onClick={onContactUser}
              disabled={isOutOfStock}
            ><IconIntercom/></PrimaryButton> : null}
          </>
        )}
      </div> : null}
    </div>
  );
};

OrderPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  authorLink: null,
  payoutDetailsWarning: null,
  titleDesktop: null,
  subTitle: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
  pageUrl: '',
};

OrderPanel.propTypes = {
  rootClassName: string,
  pageUrl: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  validListingTypes: arrayOf(
    shape({
      listingType: string.isRequired,
      transactionType: shape({
        process: string.isRequired,
        alias: string.isRequired,
        unitType: string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  isOwnListing: bool,
  author: oneOfType([propTypes.user, propTypes.currentUser]).isRequired,
  authorLink: node,
  payoutDetailsWarning: node,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  titleDesktop: node,
  subTitle: oneOfType([node, string]),
  onManageDisableScrolling: func.isRequired,

  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  onContactUser: func,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  marketplaceCurrency: string.isRequired,
  dayCountAvailableForBooking: number.isRequired,
  marketplaceName: string.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(OrderPanel);
