/**
 * This is a wrapper component for different Layouts.
 * Navigational 'aside' content should be added to this wrapper.
 */
import React, { useEffect, useState } from 'react';
import { node, number, string, shape } from 'prop-types';
import { compose } from 'redux';

import { FormattedMessage } from '../../../util/reactIntl';
import { withViewport } from '../../../util/uiHelpers';

import { TabNav } from '../../../components';

import { createGlobalState } from './hookGlobalState';

import css from './LayoutSideNavigation.module.css';
import { isApprovedOwner, isApprovedSeller } from '../../../util/userHelpers';

const MAX_HORIZONTAL_NAV_SCREEN_WIDTH = 1023;

// Add global state for tab scrolling effect
const initialScrollState = { scrollLeft: 0 };
const { useGlobalState } = createGlobalState(initialScrollState);

// Horizontal scroll animation using element.scrollTo()
const scrollToTab = (currentPage, scrollLeft, setScrollLeft) => {
  const el = document.querySelector(`#${currentPage}Tab`);

  if (el) {
    // el.scrollIntoView doesn't work with Safari and it considers vertical positioning too.
    // This scroll behaviour affects horizontal scrolling only
    // and it expects that the immediate parent element is scrollable.
    const parent = el.parentElement;
    const parentRect = parent.getBoundingClientRect();
    const maxScrollDistance = parent.scrollWidth - parentRect.width;

    const hasParentScrolled = parent.scrollLeft > 0;
    const scrollPositionCurrent = hasParentScrolled ? parent.scrollLeft : scrollLeft;

    const tabRect = el.getBoundingClientRect();
    const diffLeftBetweenTabAndParent = tabRect.left - parentRect.left;
    const tabScrollPosition = parent.scrollLeft + diffLeftBetweenTabAndParent;

    const scrollPositionNew =
      tabScrollPosition > maxScrollDistance
        ? maxScrollDistance
        : parent.scrollLeft + diffLeftBetweenTabAndParent;

    const needsSmoothScroll = scrollPositionCurrent !== scrollPositionNew;

    if (!hasParentScrolled || (hasParentScrolled && needsSmoothScroll)) {
      // Ensure that smooth scroll animation uses old position as starting point after navigation.
      parent.scrollTo({ left: scrollPositionCurrent });
      // Scroll to new position
      parent.scrollTo({ left: scrollPositionNew, behavior: 'smooth' });
    }
    // Always keep track of new position (even if smooth scrolling is not applied)
    setScrollLeft(scrollPositionNew);
  }
};

const LayoutWrapperAccountSettingsSideNavComponent = props => {
  const [scrollLeft, setScrollLeft] = useGlobalState('scrollLeft');

  useEffect(() => {
    const { currentPage, viewport } = props;
    let scrollTimeout = null;

    const { width } = viewport;
    const hasViewport = width > 0;
    const hasHorizontalTabLayout = hasViewport && width <= MAX_HORIZONTAL_NAV_SCREEN_WIDTH;

    // Check if scrollToTab call is needed (tab is not visible on mobile)
    if (hasHorizontalTabLayout) {
      scrollTimeout = window.setTimeout(() => {
        scrollToTab(currentPage, scrollLeft, setScrollLeft);
      }, 300);
    }

    return () => {
      // Update scroll position when unmounting
      const el = document.querySelector(`#${currentPage}Tab`);
      if (el) {
        setScrollLeft(el.parentElement.scrollLeft);
        if (scrollTimeout) {
          clearTimeout(scrollTimeout);
        }
      }
    };
  });

  const { currentPage, currentUser, onboardingType, onboardingCurrentTab, setOnboardingCurrentTab, onboardingProgress } = props;

  let isOwner = isApprovedOwner(currentUser);
  let isApproved = isApprovedSeller(currentUser);

  let sellerType = currentUser?.attributes?.profile?.publicData?.userType === 'provider' ? currentUser?.attributes?.profile?.publicData?.sellerType ? currentUser?.attributes?.profile?.publicData?.sellerType : 'owner' : null;

  let hasCompleteProfile = currentUser && currentUser?.profileImage && currentUser?.attributes?.profile?.publicData?.greetingHeading;
  let hasBankAccount = currentUser && currentUser?.stripeAccount;

  const tabs = [
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.contactDetailsTabTitle" />,
      selected: currentPage === 'ContactDetailsPage',
      id: 'ContactDetailsPageTab',
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.passwordTabTitle" />,
      selected: currentPage === 'PasswordChangePage',
      id: 'PasswordChangePageTab',
      linkProps: {
        name: 'PasswordChangePage',
      },
    },
    ...(isOwner ? [{
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.paymentsTabTitle" />,
      selected: currentPage === 'StripePayoutPage',
      id: 'StripePayoutPageTab',
      linkProps: {
        name: 'StripePayoutPage',
      },
    }] : []),
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.paymentMethodsTabTitle" />,
      selected: currentPage === 'PaymentMethodsPage',
      id: 'PaymentMethodsPageTab',
      linkProps: {
        name: 'PaymentMethodsPage',
      },
    },
  ];

  const onboardingTabs = [
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.completeProfilePageTitle" />,
      selected: onboardingCurrentTab === 'profile',
      id: 'profileTab',
      type: 'button',
      buttonProps: {
        onClick: () => setOnboardingCurrentTab('profile'),
      }
    },
    (sellerType === 'owner' || sellerType === 'insider' || sellerType === 'innovator') && {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.paymentPayoutPageTitle" />,
      selected: onboardingCurrentTab === 'payment',
      id: 'paymentTab',
      type: 'button',
      buttonProps: {
        onClick: () => setOnboardingCurrentTab('payment'),
        disabled: onboardingProgress ? onboardingProgress?.profile ? false : true : true
      }
    },
    (sellerType === 'insider' || sellerType === 'innovator' || sellerType === 'associate') && {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.createListingPageTitle" />,
      selected: onboardingCurrentTab === 'listing',
      id: 'listingTab',
      type: 'button',
      buttonProps: {
        onClick: () => setOnboardingCurrentTab('listing'),
        disabled: onboardingProgress ? sellerType === 'associate' && onboardingProgress?.profile ? false : onboardingProgress?.payment ? false : true : true
      }
    }
  ].filter(Boolean); // Filter out false values

  return <TabNav
    rootClassName={css.tabs}
    tabRootClassName={css.tab}
    tabs={currentPage === 'OnboardingPage' || currentPage === 'OnboardingPageReturnURL' ? onboardingTabs : tabs}
  />;
};

LayoutWrapperAccountSettingsSideNavComponent.defaultProps = {
  className: null,
  rootClassName: null,
  children: null,
  currentPage: null,
};

LayoutWrapperAccountSettingsSideNavComponent.propTypes = {
  children: node,
  className: string,
  rootClassName: string,
  currentPage: string,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const LayoutWrapperAccountSettingsSideNav = compose(withViewport)(
  LayoutWrapperAccountSettingsSideNavComponent
);

export default LayoutWrapperAccountSettingsSideNav;
